import axios from '@/utils/request'

// 交易查询-订单查询
export function getReceiptOrders(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/receipt/orders`,
    method: 'get',
		params
  })
}
// 交易查询-订单搜索
export function getReceiptOrdersSearch(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/receipt/orders/search`,
    method: 'get',
		params
  })
}
// 交易查询-订单详情
export function getReceiptOrdersDetail(orderId) {
  return axios({
    url: `/manager/trade/receipt/orders/${orderId}`,
    method: 'get'
  })
}
// 交易查询-订单退款详情
export function getReOrReDetail(orderId) {
  return axios({
    url: `/manager/trade/receipt/orders/${orderId}/refund`,
    method: 'get'
  })
}
// 交易查询-订单同步
export function getReOrResync(orderId,type) {
  console.log("交易订单同步请求",type)
  return axios({
    url: `/manager/trade/receipt/orders/${orderId}/sync`,
    method: 'get',
    Headers:{
      'type':type? type:0
    }
  })
}
// 交易查询-订单通知
export function setReOrReNotify(orderId,type) {
  return axios({
    url: `/manager/trade/receipt/orders/${orderId}/notify`,
    method: 'post',
    Headers:{
      'type':type? type:0
    }
  })
}
// 交易查询-申请退款
export function setRefund(orderId, data) {
  return axios({
    url: `/manager/trade/receipt/orders/${orderId}/refund`,
    method: 'post',
		data
  })
}
// 交易查询-退款查询
export function getRefunds(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/receipt/refunds`,
    method: 'get',
		params
  })
}
// 交易查询-退款搜索
export function getRefundsSearch(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/receipt/refunds/search`,
    method: 'get',
		params
  })
}
// 交易查询-退款订单详情
export function getReReDetail(refundId) {
  return axios({
    url: `/manager/trade/receipt/refunds/${refundId}/order`,
    method: 'get'
  })
}
// 交易查询-退款详情
export function getReDetail(refundId) {
  return axios({
    url: `/manager/trade/receipt/refunds/${refundId}`,
    method: 'get'
  })
}
// 交易查询-退款同步
export function getReResync(refundId,type) {
  return axios({
    url: `/manager/trade/receipt/refunds/${refundId}/sync`,
    method: 'get',
    Headers:{
      'type':type? type:0
    }
  })
}
// 交易查询-退款通知
export function setReReNotify(refundId,type) {
  return axios({
    url: `/manager/trade/receipt/refunds/${refundId}/notify`,
    method: 'post',
    Headers:{
      'type':type? type:0
    }
  })
}
// 交易查询-退款申请审核
export function setCheck(data) {
  return axios({
    url: `/manager/trade/receipt/refunds/check`,
    method: 'put',
		data
  })
}
// 交易查询-退款审核查询
export function getRefundchecks(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/receipt/refundchecks`,
    method: 'get',
		params
  })
}
// 交易查询-退款审核搜索
export function getRefundchecksSearch(params) {
  // params.loading = true
  return axios({
    url: `/manager/trade/receipt/refundchecks/search`,
    method: 'get',
		params
  })
}
// 交易查询-导出
export function exportReceiptOrders(params) {
  return axios({
    responseType:'blob',
    url: `/manager/trade/receipt/orders/export`,
    method: 'post',
    params
  })
}
// 交易查询-退款订单导出
export function exportRefunds(params) {
  return axios({
    responseType:'blob',
    url: `/manager/trade/receipt/refunds/export`,
    method: 'post',
    params
  })
}

// 交易查询-交易详情-聚合码通知信息-补发
export function getOrderReissue(orderId) {
  return axios({
    url:`/manager/trade/receipt/orders/${orderId}/wxNotify`,
    method: 'post'
  })
}


// 退款查询-退款详情-聚合码通知信息-补发
export function getRefundIdReissue(refundId) {
  return axios({
    url:`/manager/trade/receipt/refunds/${refundId}/wxNotify`,
    method: 'post'
  })
}


// 分账申请退款
export function setAllocRefund(data) {
  return axios({
    url: `/manager/trade/receipt/orders/${data.orderId}/allocRefund`,
    method: 'post',
    data
  })
}

